import React, { Fragment, useEffect, useState } from 'react'
import InputSearch from './InputSearch'
import authQueries from '../../queries/authQueries';
import SelectSearch from './SelectSearch';
import { ChevronDown, ChevronUp } from 'lucide-react';
import RegisteredCheckSearch from './RegisteredCheckSearch';
import { userStore } from '../../store/userStore';
import { useAdvancedSearch } from '../../store/advancedSearchStore';
import moment from 'moment';
import toast from 'react-hot-toast';
import { message } from 'antd';

const AdvancedSearch = ({report, visaapproved, finishReport, onhold, readytomark, feepayvendor, collegeMarketing}) => {

    const store = userStore()

    const [expand, setExpand] = useState(false);
    const [masterData, setMasterData] = useState({})

    const name = useAdvancedSearch((state) => state.name);
    const mobile = useAdvancedSearch((state) => state.mobile);
    const email = useAdvancedSearch((state) => state.email);
    const visa = useAdvancedSearch((state) => state.visa);
    const status = useAdvancedSearch((state) => state.status);
    const reffered = useAdvancedSearch((state) => state.reffered);
    const secondary_reffered = useAdvancedSearch((state) => state.secondary_reffered);
    const appQualify = useAdvancedSearch((state) => state.appQualify);
    const intake = useAdvancedSearch((state) => state.intake);
    const office = useAdvancedSearch((state) => state.office);
    const district = useAdvancedSearch((state) => state.district);
    const college = useAdvancedSearch((state) => state.college);
    const appStatus = useAdvancedSearch((state) => state.appStatus);
    const hot = useAdvancedSearch((state) => state.hot);
    const warm = useAdvancedSearch((state) => state.warm);
    const cold = useAdvancedSearch((state) => state.cold);
    const registered = useAdvancedSearch((state) => state.registered);
    const counselor = useAdvancedSearch((state) => state.counselor);
    const documentStaff = useAdvancedSearch((state) => state.documentStaff);
    const pgCoordinator = useAdvancedSearch((state) => state.pgCoordinator);
    const admissionStaff = useAdvancedSearch((state) => state.admissionStaff);
    const feeCoordinator = useAdvancedSearch((state) => state.feeCoordinator);
    const visaStaff = useAdvancedSearch((state) => state.visaStaff);
    const sopStaff = useAdvancedSearch((state) => state.sopStaff);
    const spCoordinator = useAdvancedSearch((state) => state.spCoordinator);
    const createdFrom = useAdvancedSearch((state) => state.createdFrom);
    const createdTo = useAdvancedSearch((state) => state.createdTo);
    const financialYear = useAdvancedSearch((state) => state.financialYear);
    const prosStatus = useAdvancedSearch((state) => state.prosStatus);
    const activeOrAll = useAdvancedSearch((state) => state.activeOrAll);
    const feeVendor = useAdvancedSearch((state) => state.feeVendor);

    const setName = useAdvancedSearch((state) => state.setName);
    const setMobile = useAdvancedSearch((state) => state.setMobile);
    const setEmail = useAdvancedSearch((state) => state.setEmail);
    const setVisa = useAdvancedSearch((state) => state.setVisa);
    const setStatus = useAdvancedSearch((state) => state.setStatus);
    const setReffered = useAdvancedSearch((state) => state.setReffered);
    const setSecondaryReffered = useAdvancedSearch((state) => state.setSecondaryReffered);
    const setAppQualify = useAdvancedSearch((state) => state.setAppQualify);
    const setIntake = useAdvancedSearch((state) => state.setIntake);
    const setOffice = useAdvancedSearch((state) => state.setOffice);
    const setDistrict = useAdvancedSearch((state) => state.setDistrict);
    const setCollege = useAdvancedSearch((state) => state.setCollege);
    const setAppStatus = useAdvancedSearch((state) => state.setAppStatus);
    const setActiveOrAll = useAdvancedSearch((state) => state.setActiveOrAll);
    const setHot = useAdvancedSearch((state) => state.setHot);
    const setWarm = useAdvancedSearch((state) => state.setWarm);
    const setCold = useAdvancedSearch((state) => state.setCold);
    const setRegistered = useAdvancedSearch((state) => state.setRegistered);
    const setCounselor = useAdvancedSearch((state) => state.setCounselor);
    const setDocumentStaff = useAdvancedSearch((state) => state.setDocumentStaff);
    const setPgCoordinator = useAdvancedSearch((state) => state.setPgCoordinator);
    const setAdmissionStaff = useAdvancedSearch((state) => state.setAdmissionStaff);
    const setFeeCoordinator = useAdvancedSearch((state) => state.setFeeCoordinator);
    const setVisaStaff = useAdvancedSearch((state) => state.setVisaStaff);
    const setSopStaff = useAdvancedSearch((state) => state.setSopStaff);
    const setSpCoordinator = useAdvancedSearch((state) => state.setSpCoordinator);
    const setCreatedFrom = useAdvancedSearch((state) => state.setCreatedFrom);
    const setCreatedTo = useAdvancedSearch((state) => state.setCreatedTo);
	const setFinancialYear = useAdvancedSearch((state) => state.setFinancialYear)
    const setProsStatus = useAdvancedSearch((state) => state.setProsStatus);
    const setFeeVendor = useAdvancedSearch((state) => state.setFeeVendor);

    const advancedMasterList = authQueries.useAdvancedSearchMutation(
        (response) => {
            setMasterData(response?.data)
        }
    );

    useEffect(() => {
        advancedMasterList.mutateAsync({
            visa: visa, 
            collegeMarketing: collegeMarketing ? true:false,
            reffered
        })
    }, [visa, reffered])

    const selectCollege = (item) => {
        setCollege(item)
        if(visaapproved){
            setAppStatus(7)
        }
    }

    const bmOffice = store?.user?.office?.map(item=>{
        return item?.office
    })

    const chipDataVisaApproved = [
        {id: 17, name: "Visa Approved"},
        {id: 18, name: "Visa Refused"},
        {id: 20, name: "Refund Completed"}
    ]

    const chipData = [
        {
            id: 1,
            label: "HOT",
            state: hot,
            setState: setHot,
        },
        {
            id: 2,
            label: "WARM",
            state: warm,
            setState: setWarm,
        },
        {
            id: 3,
            label: "COLD",
            state: cold,
            setState: setCold,
        }
    ]

    const inputSearchArry = [
        {
            label: "Applicant Name",
            size: 2,
            state: name,
            setState: setName,
            type: "text",
            input: InputSearch,
            simple: true
        },
        {
            label: "Contact Number",
            size: 2,
            state: mobile,
            setState: setMobile,
            type: "number",
            input: InputSearch,
            simple: true
        },
        {
            label: "Email",
            size: 2,
            state: email,
            setState: setEmail,
            type: "text",
            input: InputSearch,
            simple: true
        },
        {
            label: "Visa Type",
            size: 2,            
            state: visa,
            setState: setVisa,
            input: SelectSearch,
            value:  (store?.user?.role === "MANAGER" || store?.user?.role === "REGISTRATION" || store?.user?.role === "SENIORPROGRAMMANAGER") ? store?.user?.visa : masterData?.visatype,
            visa: (store?.user?.role === "MANAGER" || store?.user?.role === "REGISTRATION" || store?.user?.role === "SENIORPROGRAMMANAGER") ? true : false,
            name: 'description',
            simple: true
        },
        {
            label: "Prospect Status",
            size: 2,            
            state: status,
            setState: setStatus,
            input: SelectSearch,
            value: masterData?.prospectstatus,
            name: 'name',
            hidden: visaapproved ? true : false,
            simple: true
        },
        {
            label: "Registration Status",
            size: 2,            
            state: registered,
            setState: setRegistered,
            input: RegisteredCheckSearch,
            hidden: (store?.user?.role === "ADMISSIONSTAFF" || report) ? true : false,
            simple: true
        },
        {
            label: "Branch Office",
            size: 2,            
            state: office,
            setState: setOffice,
            input: SelectSearch,
            value: (store?.user?.role === "BMANAGER" || store?.user?.role === "SENIORPROGRAMMANAGER") ? bmOffice : masterData?.office,
            hidden: store?.user?.role === "RM" ? true : false,
            name: 'name',
            simple: (store?.user?.role === "ADMISSIONSTAFF" || report) ? true: false,
        },
        {
            label: "Intake",
            size: 2,            
            state: intake,
            setState: setIntake,
            input: SelectSearch,
            value: masterData?.intake,
            name: 'intake',
            simple: visaapproved ? true:false
        },
        {
            label: "District",
            size: 2,            
            state: district,
            setState: setDistrict,
            input: SelectSearch,
            value: masterData?.district,
            name: 'name',
            simple: false
        },
        {
            label: "Referred By",
            size: 2,            
            state: reffered,
            setState: setReffered,
            input: SelectSearch,
            value: masterData?.refferal,
            hidden: store?.user?.role === "MARKETINGASSOCIATE" ? true : false,
            name: 'refferal',
            simple: false
        },
        {
            label: "Secondary Referred By",
            size: 2,            
            state: secondary_reffered,
            setState: setSecondaryReffered,
            input: SelectSearch,
            value: masterData?.secondaryReffered,
            hidden: store?.user?.role === "MARKETINGASSOCIATE" ? true : false,
            name: 'name',
            simple: false
        },
        {
            label: "College",
            size: 2,            
            state: college,
            setState: selectCollege,
            input: SelectSearch,
            value: masterData?.college,
            name: 'name',
            simple: false,
        },
        {
            label: "Applicant Qualification",
            size: 2,            
            state: appQualify,
            setState: setAppQualify,
            input: SelectSearch,
            value: masterData?.qualification,
            name: 'name',
            simple: false
        },
        {
            label: "Application Status",
            size: 2,            
            state: appStatus,
            setState: setAppStatus,
            input: SelectSearch,
            value: masterData?.applicationstatus,
            name: 'name',
            simple: false,
        },
        {
            label: "Active / All Clients",
            size: 2,            
            state: activeOrAll,
            setState: setActiveOrAll,
            input: SelectSearch,
            value: [{id: "ACTIVE", name: "Active Clients"}, {id: "ALL", name: "All Clients"}],
            name: 'name',
            simple: false,
            hidden: store?.user?.role === "CEO" ? false : true
        },
        {
            label: "Fee Payment Vendors",
            size: 2,            
            state: feeVendor,
            setState: setFeeVendor,
            input: SelectSearch,
            value: [{id: "Flywire", name: "Flywire"}, {id: "Unimoni", name: "Unimoni"}, {id: "Paul Merchant", name: "Paul Merchant"},, {id: "CIBC", name: "CIBC"},, {id: "Paymytuition", name: "Paymytuition"},, {id: "Wire Transfer (Bank Transfer)", name: "Wire Transfer (Bank Transfer)"},, {id: "Card Payments (Debit / Credit)", name: "Card Payments (Debit / Credit)"},, {id: "ICICI Forex", name: "ICICI Forex"},, {id: "Others", name: "Others"}],
            name: 'name',
            simple: false,
            hidden: feepayvendor ? false : true,
        }
    ]

    const findUser =(role, isHiddenNotAssigned)=>{
        let filterData = []
        if(!isHiddenNotAssigned){
            filterData.push({id:"NOTASSIGNED", name:"Not Assigned"})
        }
        if(role === 3 && store?.user?.role === "BMANAGER"){
            filterData.push({id:"INACTIVECOUNSELOR", name:"Inactive Counselor"})
        }
        const data = masterData?.user?.filter(user => user.roles.includes(role))
        data?.map((item) => {
            filterData.push({
                id: item.id,
                name: item.name
            })
        })
        return filterData
    }

    const userSearchArry = [
        {
            label: "Counselor",
            size: 2,            
            state: counselor,
            setState: setCounselor,
            input: SelectSearch,
            value: store.user.role === "SENIORPROGRAMMANAGER" ? masterData?.seniorpgmusers : store?.user?.role === "TELECALLER" ? findUser(3) : findUser(3, true),
            hidden: store?.user?.role === "COUNSELOR" ? true : false,
            name: 'name',
            simple: false,
        },
        {
            label: "Document Staff",
            size: 2,            
            state: documentStaff,
            setState: setDocumentStaff,
            input: SelectSearch,
            value: findUser(5),
            hidden: store?.user?.role === "DOCUMENTSTAFF" ? true : false,
            name: 'name',
            simple: false,
        },
        {
            label: "Program Coordinator",
            size: 2,            
            state: pgCoordinator,
            setState: setPgCoordinator,
            input: SelectSearch,
            value: findUser(6),
            hidden: store?.user?.role === "PROGRAMCOORDINATOR" ? true : false,
            name: 'name',
            simple: false,
        },
        {
            label: "Admission Staff",
            size: 2,            
            state: admissionStaff,
            setState: setAdmissionStaff,
            input: SelectSearch,
            value: findUser(7),
            hidden: store?.user?.role === "ADMISSIONSTAFF" ? true : false,
            name: 'name',
            simple: false,
        },
        {
            label: "Fee Coordinator",
            size: 2,            
            state: feeCoordinator,
            setState: setFeeCoordinator,
            input: SelectSearch,
            value: findUser(8),
            hidden: store?.user?.role === "FEECOORDINATOR" ? true : false,
            name: 'name',
            simple: false,
        },
        {
            label: "Visa Staff",
            size: 2,            
            state: visaStaff,
            setState: setVisaStaff,
            input: SelectSearch,
            value: findUser(8),
            hidden: store?.user?.role === "VISASTAFF" ? true : false,
            name: 'name',
            simple: false,
        },
        {
            label: "SOP Staff",
            size: 2,            
            state: sopStaff,
            setState: setSopStaff,
            input: SelectSearch,
            value: findUser(22),
            hidden: store?.user?.role === "SOPSTAFF" ? true : false,
            name: 'name',
            simple: false,
        },
        {
            label: "Senior Program Coordinator",
            size: 2,            
            state: spCoordinator,
            setState: setSpCoordinator,
            input: SelectSearch,
            value: findUser(20),
            hidden: store?.user?.role === "SENIORPROGRAMMANAGER" ? true : false,
            name: 'name',
            simple: false,
        },
    ]


    const dateFilterArry = [
        {
            label: "Financial Year",
            size: 2,            
            state: financialYear,
            setState: setFinancialYear,
            type: "select",
            input: SelectSearch,
            value: [
                {
                    name: "Previous FY",
                    id: "PREVIOUS",
                },
                {
                    name: "Current FY",
                    id: "CURRENT"
                },
                {
                    name: "Future FY",
                    id: "FUTURE"
                }
            ],
            name: 'name',
            hidden: report ? false : true,
            simple: false,
        },
        {
            label: "From Date",
            size: 2,            
            state: createdFrom,
            setState: setCreatedFrom,
            type: "date",
            input: InputSearch,
            name: 'name',
            simple: false,
            hidden: readytomark ? true : false,
        },
        {
            label: "To Date",
            size: 2,            
            state: createdTo,
            setState: setCreatedTo,
            type: "date",
            input: InputSearch,
            name: 'name',
            simple: false,
            hidden: readytomark ? true : false,
        },
    ]

    const handleChangeChip=(item)=>{
        const exist = prosStatus.includes(item?.id)
        if(exist){
            let pro = prosStatus.filter(element => element !== item?.id)
            if(pro.length === 0){
                return toast.error("Please select at least one status.")
            }
            setProsStatus(pro)
        }
        else{
            setProsStatus([...prosStatus, item?.id])
        }
        
    }


  return (
    <Fragment>
        <div className="grid grid-cols-12 max-[1389px]:grid-cols-10 max-[1163px]:grid-cols-8 max-[929px]:grid-cols-6 max-[585px]:grid-cols-4 max-[445px]:grid-cols-2 gap-x-6 gap-y-5 !mb-5">
            {
                inputSearchArry?.map((item, index)=> {
                    if(item?.blank && !item?.hidden){
                        return(
                        <div className={`col-span-${item?.size} flex flex-col gap-2`}/>
                        )
                    }
                    if(item?.hidden){
                        return
                    }
                    return(
                    <Fragment key={index}>
                        {
                            (item?.simple || expand) &&
                            <div key={index} className={`col-span-${item?.size} flex flex-col gap-2`}>
                                <item.input item={item} />
                            </div>
                        }
                    </Fragment>
                )})
            }
        </div>        
        
        {
            expand &&
            <Fragment>
                <div className="grid grid-cols-12 max-[1389px]:grid-cols-10 max-[1163px]:grid-cols-8 max-[929px]:grid-cols-6 max-[585px]:grid-cols-4 max-[445px]:grid-cols-2 gap-x-6 gap-y-5 !mb-5">
                    {
                        userSearchArry?.map((item, index)=> {
                            if(item?.blank && !item?.hidden){
                                return(
                                <div className={`col-span-${item?.size} flex flex-col gap-2`}/>
                                )
                            }
                            if(item?.hidden){
                                return
                            }
                            return(
                            <Fragment key={index}>
                                {
                                    (item?.simple || expand) &&
                                    <div key={index} className={`col-span-${item?.size} flex flex-col gap-2`}>
                                        <item.input item={item} />
                                    </div>
                                }
                            </Fragment>
                        )})
                    }
                </div>

                <div className="grid grid-cols-12 max-[1389px]:grid-cols-10 max-[1163px]:grid-cols-8 max-[929px]:grid-cols-6 max-[585px]:grid-cols-4 max-[445px]:grid-cols-2 gap-x-6 gap-y-5 !mb-5">
                    {
                        dateFilterArry?.map((item, index)=> {
                            if(item?.blank && !item?.hidden){
                                return(
                                <div className={`col-span-${item?.size} flex flex-col gap-2`}/>
                                )
                            }
                            if(item?.hidden){
                                return
                            }
                            return(
                            <Fragment key={index}>
                                {
                                    (item?.simple || expand) &&
                                    <div key={index} className={`col-span-${item?.size} flex flex-col gap-2`}>
                                        <item.input item={item} />
                                    </div>
                                }
                            </Fragment>
                        )})
                    }
                </div>
            </Fragment>
        }
        
        {(!finishReport && !onhold) &&
            <div className='grid-cols-2 !mb-5'>
                <div className="col-span-2 max-[585px]:col-span-4 max-[445px]:col-span-2 justify-center flex flex-col gap-2">
                    <div className="text-[12px] h-4 font-[500]">Profile Status</div>
                    <div className="flex gap-2 items-center flex-wrap">
                        {
                            chipData.map((item, index)=> (
                                <div
                                key={index} 
                                onClick={()=>{item?.state ? item?.setState(false):item?.setState(true)}} 
                                className={`${item.state ? "bg-[#28a745]" : "bg-[#ff4d4f]"} flex items-center h-[30px] rounded-full cursor-pointer justify-start`}
                                >
                                    <div className="text-[24px] flex pl-[4px]">
                                        {
                                            item.state ?
                                            <i className='icon-cancel1 text-white'/>:
                                            <i className='icon-plus1 text-white' />
                                        }
                                    </div>
                                    <div className="text-[10px] text-white font-semibold pl-2 pr-3">
                                        {item.label} Client
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
        }

        {
            finishReport &&
            <div className="col-span-6 max-[585px]:col-span-4 max-[445px]:col-span-2 justify-center flex flex-col gap-2">
                <div className="text-[12px] h-4 font-[500]">Prospect Status</div>
                <div className="flex gap-2 h-10 items-center flex-wrap">
                    {
                        chipDataVisaApproved?.map((item, index)=> (
                            <div 
                            key={index} 
                            onClick={()=>{handleChangeChip(item)}} 
                            className={`${prosStatus?.find(element => element === item?.id ) ? "bg-[#28a745]" : "bg-[#ff4d4f]"} flex items-center h-[30px] rounded-full cursor-pointer justify-start`}
                            >
                                <div className="text-[24px] flex pl-[4px]">
                                    {
                                        item.state ?
                                        <i className='icon-cancel1 text-white'/>:
                                        <i className='icon-plus1 text-white' />
                                    }
                                </div>
                                <div className="text-[10px] text-white font-semibold pl-2 pr-3">
                                    {item.name}
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
        }
        
        <div className="row advance-search-btn-cntnr">
            <div className="col-md-12 mb-6" style={{ position: 'relative' }}>
                <hr />
                <button onClick={() => { setExpand(!expand) }} className="advance-search-btn search">
                    {
                        !expand ? 
                        <div className='flex gap-2'>
                            <div>Advanced Search</div>
                            <div><ChevronDown size="16" /></div>
                        </div>:
                        <div className='flex gap-2'>
                            <div>Simple Search</div>
                            <div><ChevronUp size="16" /></div>
                        </div>
                    }
                </button>
            </div>
        </div>
      
    </Fragment>
  )
}

export default AdvancedSearch
