 import { yupResolver } from '@hookform/resolvers/yup'
import React,{useState, useEffect, Fragment} from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import authQueries from '../../queries/authQueries'
import * as yup from 'yup'
import { message as messages } from 'antd'
import { Checkbox, Loading, Switch } from '@nextui-org/react'
import { userStore } from '../../store/userStore'
import moment from 'moment'
import {AutoCompleteAntd, AutoCompleteAntdOffice, AutoCompleteChild, AutoCompleteChilds, AutoCompleteIntake}  from '../Clients/AddClients/AutoComplete'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/bootstrap.css'
import LoaderCommon from '../Common/LoaderCommon'
import { IconBackspace } from '@tabler/icons-react'
import { IconDeviceFloppy } from '@tabler/icons-react'
import parsePhoneNumberFromString from 'libphonenumber-js'
import toast from 'react-hot-toast'


const AssociatesForm = ({lead_status}) => {

    const appStore = userStore();

    const [resultVisa, setResultVisa] = useState([]);
    const [intakeResult, setIntakeResult] = useState([]);

    const [name, setName] = useState('');
    const [mName, setMName] = useState('')
    const [lName, setLName] = useState('')
    const [mobile, setMobile] = useState(appStore?.appAllData?.applicant?.mobile_no);
    const [whatsapp, setWhatsapp] = useState(appStore?.appAllData?.applicant?.whatsapp_no);
    const [email, setEmail] = useState('');
    const [visaid, setVisaId] = useState(appStore?.appAllData?.applicant?.visa_type_id);
    const [image, setImage] = useState(appStore?.appAllData?.applicant?.photo)
    const [loader, setLoader] = useState(true)
    const [emailExist, setEmailExist] = useState('')
    const [mobileExist, setMobileExist] = useState('')
    const [mobileValid, setMobileValid] = useState('')
    const [whatsappExist, setWhatsappExist] = useState('')
    const [code, setCode] = useState('in');

    const [visaData, setVisaData] = useState('');
    const [intakeData, setIntakeData] = useState('');
    const [visaidErr, setVisaIdErr] = useState(false)

	const viewAllApplicationEditData = authQueries.useApplicationMutationViewAll(
        async (response) => {
            if (response.data) {
                await appStore.setApplicationAllData(response.data)
                reset({...response.data.applicant})
            }
        }
    )

    const visaType = (response) => {
        setVisaData(appStore?.appAllData?.applicant?.visa_type_id)
        let dataArray = []
        for(let datas of response){
            const dataValues = {
                label:datas?.name,
                value:datas?.id
            }

            dataArray = [...dataArray, dataValues]
        }
        setResultVisa(dataArray)
    }


    const inTake = (response) => {
        if(visaid !== appStore?.appAllData?.applicant?.visa_type_id){
            setValue('intake_id', "")
            setIntakeData(null)
        }else{
            setValue('intake_id', appStore?.appAllData?.applicant?.intake_id)
            setIntakeData(appStore?.appAllData?.applicant?.intake_id) 
        }
        let dataArray = []
        for(let datas of response){
            const dataValues = {
                label:datas?.name,
                value:datas?.id
            }

            dataArray = [...dataArray, dataValues]
        }
        setIntakeResult(dataArray)
    }


    const refferalView = authQueries.useRefferalMutationView(
        (response) => {
            setValue('referral_id', appStore?.user?.refid)
        }
    );

    const counselorList = authQueries.useOfficeCounselorAssociatesMutation(
        (response) => {
            setLoader(false)
        }   
    );


    const formMastersList = authQueries.useApplicantFormMastersList(
		(res)=>{
            visaType(res?.data?.visatype)
            inTake(res?.data?.intake)
            setLoader(false)
		}
	)

    const masterCall = async ()=> {
        reset({...appStore?.appAllData?.applicant})
        await refferalView.mutateAsync(appStore?.user?.refid)
        counselorList.mutateAsync({search:'', role:3, ref: appStore?.user?.refid})
    }

    useEffect(()=>{  
        formMastersList.mutateAsync({visa: visaid ? visaid :appStore?.appAllData?.applicant?.visa_type_id})
    },[visaid])

    useEffect(()=>{  
        masterCall();
    },[])

    const applicantAddSchema = yup.object({
        visa_type_id: yup
            .string('⚠ Please enter visa type')
            .required('⚠ Visa type is required'),
        intake_id: yup
            .string('⚠ Please enter intake')
            .required('⚠ Intake is required'),
        applicant_name: yup
            .string('⚠ Please enter applicant firstname')
            .required('⚠ Applicant firstname is required'),
        // email: yup
        //     .string('⚠ Please enter applicant email')
        //     .required('⚠ Applicant email is required')
        //     .nullable(),
        mobile_no: yup
            .string('⚠ Please enter applicant mobile')
            .required('⚠ Applicant mobile is required'),
        assign: yup
            .string('⚠ Please select counselor')
            .required('⚠ Counselor is required'),
    });

    const applicantEditSchema = yup.object({
        visa_type_id: yup
            .string('⚠ Please enter visa type')
            .required('⚠ Visa type is required'),
        intake_id: yup
            .string('⚠ Please enter intake')
            .required('⚠ Intake is required'),
        applicant_name: yup
            .string('⚠ Please enter applicant firstname')
            .required('⚠ Applicant firstname is required'),
        // email: yup
        //     .string('⚠ Please enter applicant email')
        //     .required('⚠ Applicant email is required')
        //     .nullable(),
        mobile_no: yup
            .string('⚠ Please enter applicant mobile')
            .required('⚠ Applicant mobile is required'),
    });
      
    const navigate = useNavigate();

    const { register, reset, control, handleSubmit, formState:{ errors }, setValue, getValues, setError} = useForm({
         resolver: yupResolver(appStore?.appid ? applicantEditSchema : applicantAddSchema)
    });

    const addApplication = authQueries.useApplicantMutationAdd(
        (response) => {
            if(response?.message === "Contact Number Already Exist."){
                toast.error("Contact Number Already Exist.")
            }
            if(response?.message === "Email Already Exist."){
                toast.error("Email Already Exist.")
            }
            if(response?.message === "Success"){ 
                navigate('/dashboard')
                toast.success("successfully created")
            }
        }   
    );

    const onSubmit = (data) => {

        if(visaid === null){
            setVisaIdErr(true)
        }else{
            setVisaIdErr(false)
        }

        if(emailExist || mobileExist || mobileValid || whatsappExist){
            return
        }

        data.lead_status = lead_status
        addApplication.mutateAsync(data);
    }

    const editApplication = authQueries.useApplicantMutationEdit(
        (response) => {
            if(response?.message === 'Error'){
                const mobError = response?.error?.find(item => item.mobile_no)
                if(mobError){
                    setError('mobile_no', {message: mobError?.mobile_no})
                }
            }
            if(response?.message === "Success"){ 
                toast.success("successfully updated")
                navigate('/dashboard')
            }
        }   
    );

    const onEdit = (data) => {
        if(emailExist){
            return
        }
        if(mobileValid){
            return
        }

        data.lead_status = lead_status
        editApplication.mutateAsync(data);
    }


    const emailValidation =({email, visa})=>{
        if(email && email.length > 6){
            emailValidApplicant.mutateAsync({email: email, visa:visa, appId:null})
        }
    }

    const emailValidApplicant = authQueries.useEmailValidApplicant(
        (response) => {
            if(response?.message === "Email already exist"){
                setEmailExist("Email already exist")
            }else{
                setEmailExist('')
            }
        }   
    );

    const mobileValidation =({number, visa, type})=>{
        mobileValidApplicant.mutateAsync({mobile: number, visa:visa, appId:null, type: type})
    }

    const mobileValidApplicant = authQueries.useMobileValidApplicant(
        (response) => {
            if(response?.message === "Mobile already exist"){
                if(response.type === "MOBILE"){
                    setMobileExist("Mobile number already exist")
                }else if(response.type === "WHATSAPP"){
                    setWhatsappExist("Whatsapp number already exist")
                }
            }else{
                if(response.type === "MOBILE"){
                    setMobileExist('')
                }else if(response.type === "WHATSAPP"){
                    setWhatsappExist('')
                }
            }
        }   
    );


    const handleChange =(e)=>{
        const data = new FormData();
        data.append('file', e)
        imageUploadCreate.mutateAsync(data)
    }


    const imageUploadCreate = authQueries.useFileUploadCommon(
        (response) => {
            setImage(response?.file)
        }   
    );


  return (

    <div>

    {loader ? 
        <LoaderCommon /> :
        <Fragment>
            <div className="card">
                <div className="card-header">
                    <a className="card-link" data-toggle="collapse" href="#applicant-details">
                        Applicant Details -
                        <span className="applicant-details-wrpr">
                            <span>{name?<><i className="icon-user"></i>&nbsp;{name}</>:<><i className="icon-user"></i>&nbsp;{appStore?.appAllData?.applicant_name}</>}&nbsp;
                            {mName?mName:appStore?.appAllData?.middlename}&nbsp;
                            {lName?lName:appStore?.appAllData?.lastname}</span>
                            {email?<em ><i className="icon-envelope3"></i> {email}</em>:<span><i className="icon-envelope3"></i> {appStore?.appAllData?.email}</span>}
                            {mobile?<em href="#"><i className="icon-phone1"></i>&nbsp;{mobile}</em>:<span><i className="icon-phone1"></i> + {appStore?.appData?.whatsapp_no}</span>}
                        </span>
                    </a>
                    {appStore.appId?
                    <div data-fieldspending={"3"} className="tab-info fields-notcompleted">
                        <span className="icon-cancel1"></span>
                    </div>:null
                    }
                </div>
                <div id="applicant-details" className="collapse show" data-parent="#accordion">
                <form  onSubmit={handleSubmit(appStore?.appid ? onEdit : onSubmit)}>
                    <div className="card-body">
                        <div className="row">
                            <div className='col-md-12' style={{display:'flex', alignItems:'center', justifyContent:'space-between'}}>
                                <div className="form-group include-spouse">
                                </div>
                                <div className="profile-wrpr" style={{marginLeft: 0, marginRight: 0}}>
                                    <div id="image-preview" className='profile-img' style={{backgroundSize:"cover", 
                                    backgroundImage:image?
                                    `url(${image})`:`url(${require('../../assets/images/profile-avatar.jpg')})`
                                    }}>
                                        <label htmlFor="image-upload" id="image-label">Choose File</label>
                                        <input onChange={(e)=>{handleChange(e.target.files[0])}} type="file" name="image" id="image-upload" /> 
                                    </div>
                                </div>
                            </div>
                        <div className="col-md-4">
                            <div>
                                <label htmlFor="typevisa">Type of Visa:<em className="mandatory">*</em></label>
                                    <AutoCompleteChilds
                                        control={control} 
                                        data={resultVisa} 
                                        name={"visa_type_id"} 
                                        value={visaData} 
                                        emailValid={emailValidation} 
                                        mobileValid={mobileValidation}
                                        whatsappValid={mobileValidation}
                                        email={email}
                                        mobile={mobile}
                                        whatsapp={whatsapp}
                                        setVisaId={setVisaId}
                                        error={errors.visa_type_id}
                                    />
                                    <div className='error-msg'>{errors.visa_type_id?.message}</div>
                                </div>
                        </div>
                        <div className="col-md-4">
                            <div>
                                <label htmlFor="intake">Intake:<em className="mandatory">*</em></label>
                                <AutoCompleteAntd 
                                    control={control} 
                                    data={intakeResult} 
                                    name={"intake_id"} 
                                    value={intakeData} 
                                    error={errors.intake_id}
                                />
                                <div className="error-msg">{errors.intake_id?.message}</div>

                            </div>
                        </div>
                        <div className="col-md-4">
                            <div>
                                <label htmlFor="reffered-by">Referred By:<em className="mandatory">*</em></label>
                                <input
                                    disabled
                                    value={refferalView?.data?.data?.refferal}
                                    type="text"
                                    className="form-control"
                                />
                            </div>
                        </div>
                        <div className="col-md-2">
                            <div className={errors.applicant_name?"form-group error":"form-group"}>
                                <label htmlFor="applicant_name">First Name:<em className="mandatory">*</em></label>
                                <input
                                    {...register('applicant_name', {
                                        onChange: (e) => {setName(e.target.value)},
                                        onBlur: (e) => {},
                                    })}
                                    name='applicant_name' 
                                    id="applicant_name" 
                                    type="text" 
                                    className="form-control" /> 
                                <div className="error-msg">{errors.applicant_name?.message}</div>
                            </div>
                        </div>
                        <div className="col-md-2">
                            <div>
                                <label htmlFor="applicant_name">Middle Name</label>
                                <input
                                    {...register('middlename', {
                                        onChange: (e) => {setMName(e.target.value)},
                                        onBlur: (e) => {},
                                    })}
                                    name='middlename' 
                                    id="applicant_name" 
                                    type="text"
                                    className="form-control" /> 
                            </div>
                        </div>

                        <div className="col-md-2">
                            <div className="form-group">
                                <label htmlFor="applicant_name">Last Name:</label>
                                <input
                                    {...register('lastname', {
                                        onChange: (e) => {setLName(e.target.value)},
                                        onBlur: (e) => {},
                                    })}
                                    name='lastname' 
                                    id="applicant_name" 
                                    type="text"
                                    className="form-control" /> 
                            </div>
                        </div>
                        <div className="col-md-2">
                            <div  className="form-group">
                                <label htmlFor="spouse_name">Email Address:</label>
                                <input
                                {...register('email', {
                                    onChange: (e) => {setEmail(e.target.value); emailValidation({email:e.target.value, visa:visaid});},
                                    onBlur: (e) => {},
                                })}
                                id="spouse_name" 
                                type="email" 
                                className="form-control" 
                                name="email" />
                                <div className="error-msg">{errors.email?.message} {emailExist}</div>
                            </div>
                        </div>

                        <div className="col-md-2">
                            <div className={errors.mobile_no?"form-group error":"form-group"}>
                                <label htmlFor="spouse_name">Mobile Number:</label>
                                <Controller
                                    name='mobile_no'
                                    control={control}
                                    className="form-control"
                                    render={({ field: { onChange } }) => (
                                        <PhoneInput
                                            enableSearch={true}
                                            country={code}
                                            value={appStore?.appAllData?.applicant?.mobile_no}
                                            onChange={(value, country, e, formattedValue) => {
                                                const digitsOnly = formattedValue.replace(/\D/g, ''); // Remove non-digit characters
                                                const countryCodeLength = country.countryCode.length; // Get length of country code
                                                const extractedPhoneNumber = digitsOnly.slice(countryCodeLength);
                                            
                                                const parsedPhoneNumber = parsePhoneNumberFromString(`+${value}`);
                                                if (parsedPhoneNumber && parsedPhoneNumber.isValid()) {
                                                    setCode(country.countryCode)
                                                    onChange(value)    
                                                    setMobile(extractedPhoneNumber);
                                                    setMobileValid('')
                                                    mobileValidation({number:extractedPhoneNumber, visa:visaid, type:"MOBILE"});
                                                } else {
                                                    setMobileExist('')
                                                    setMobileValid("Provide valid Mobile no")
                                                }
                                            }}
                                        /> 
                                    )}
                                    rules={{ required: true }}
                                />
                                <div className="error-msg">{errors.mobile_no?.message} {mobileExist} {mobileValid}</div>
                            </div>
                        </div>

                        <div className="col-md-2">
                            <div className="form-group">
                                <label htmlFor="spouse_name">Whatsapp Number:</label>
                                <input
                                {...register("whatsapp_no", {
                                    onChange: (e) => {setWhatsapp(e.target.value); mobileValidation({number:e.target.value, visa:visaid, type:"WHATSAPP"})},
                                })} 
                                id="spouse_name"
                                type="number" 
                                className="form-control" 
                                name="whatsapp_no" 
                                />
                                <div className="error-msg">{whatsappExist}</div>
                            </div>
                        </div>

                        {
                            !appStore.appid &&
                            <div className="col-md-2">
                                <div className={errors.mobile_no?"form-group error":"form-group"}>
                                    <label htmlFor="spouse_name">Counselors:<em className="mandatory">*</em></label>
                                        <select
                                        {...register('assign')}
                                        id="spouse_name" 
                                        type="number" 
                                        className="form-control" 
                                        name="assign">
                                            <option value="">Select Counselor</option>
                                            {
                                                counselorList?.data?.data?.map((item, index) => (  
                                                    <option key={index} value={item?.user?.id}>{item?.user?.userdetail?.firstname} {item?.user?.userdetail?.lastname}</option>
                                            ))}
                                        </select>
                                    <div className="error-msg">{errors.assign?.message}</div>
                                </div>
                            </div>
                        }


                        <div className="col-md-12 text-center my-4" style={{display:'flex', gap: 12, justifyContent:'center'}}>
                            <button 
                            type='button' 
                            onClick={()=>{navigate(-1)}} 
                            className="btn-customized-colorless bg-purple-700 w-[120px] h-[40px] gap-2"
                            ><IconBackspace size="18" />Go Back</button>
                            <button
                            disabled={addApplication.isLoading}
                            type='submit' 
                            className="btn-customized-large w-[120px] h-[40px] gap-2"
                            >
                                {
                                    addApplication.isLoading ?
                                    <Loading size='xs' color="white" />:
                                    <IconDeviceFloppy size="18" />
                                }
                            Save</button>
                        </div>             
                        
                        </div>
                    </div>
                </form>

                </div>
            </div>
        </Fragment>
    }
    

    </div>

  )
}

export default AssociatesForm