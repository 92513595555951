import reportService from "../services/reportService";
import { useMutation } from "react-query";

const useCollegeMarketingList = (onData) => {
  return useMutation(reportService.collegeMarketingList, {
    onSuccess: (data) => onData(data),
  })
}

const useDistributionReport = (onData) => {
  return useMutation(reportService.distributionReport, {
    onSuccess: (data) => onData(data),
  })
}

const useDistributionReportCounselorWise = (onData) => {
  return useMutation(reportService.distributionReportCounselorWise, {
    onSuccess: (data) => onData(data),
  })
}

const useDistributionReportCounselorWiseApplicants = (onData) => {
  return useMutation(reportService.distributionReportCounselorWiseApplicants, {
    onSuccess: (data) => onData(data),
  })
}

const useDistributionReportDropdown = (onData) => {
  return useMutation(reportService.distributionReportDropdown, {
    onSuccess: (data) => onData(data),
  })
}

const useAdmissionStaffPerformanceReport = (onData) => {
  return useMutation(reportService.admissionStaffPerformanceReport, {
    onSuccess: (data) => onData(data),
  })
}

const useAdmissionStaffPerformanceReportDropdown = (onData) => {
  return useMutation(reportService.admissionStaffPerformanceReportDropdown, {
    onSuccess: (data) => onData(data),
  })
}

const reportQueries = {
    useCollegeMarketingList,
    useDistributionReport,
    useDistributionReportCounselorWise,
    useDistributionReportCounselorWiseApplicants,
    useDistributionReportDropdown,
    useAdmissionStaffPerformanceReport,
    useAdmissionStaffPerformanceReportDropdown
}

export default reportQueries